.icon-green {
  color: #3ab54a;
}
.table > tbody > tr > td > .icon {
  font-size: 25px;
  line-height: 45px;
  margin-right: 10px;
}
.icon-red {
  color: #d0021b;
}
.icon-delete {
  font-size: 25px;
  line-height: 45px;
  vertical-align: middle;
}
