.mail-footer-links {
  padding-top: 37px;
  font-size: 50px;
}
.mail-footer-links a:focus {
  text-decoration: none;
}
.mail-footer .content {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.3px;
  text-align: center;
  color: #000000;
}
.mail-template-header .panel-title {
  padding: 0 20px 0 40px !important;
}
.panel-email-preview .panel-heading img {
  width: 100%;
  padding: 0;
  margin: 0;
}
.panel-email-preview .panel-heading .panel-heading img:nth-of-type(2) {
  position: relative;
}
.add-body-block {
  display: none;
}
.mail-block:last-of-type .add-body-block {
  display: block;
}
