@import "variables";

.panel-default {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 20px;
  border: none;
}

.panel-default > .panel-heading {
  background-color: @panelHeadingBackgroundColor;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 60px;
  padding: 0px 0px 0px 0px;
  border: none;
}

.panel-default > .panel-heading > .search > .btn-group > .panel-title,
.panel-default > .panel-heading > .panel-title {
  color: @panelTitelColor;
  line-height: 60px;
  font-size: 1.5em;
  padding: 0px 20px 0px 20px;
  font-weight: 400;
}

.panel-default > .panel-heading > .search > .btn-group > .panel-title {
  font-size: 1.2em;
}

.panel-default > .panel-heading > .search {
  position: absolute;
  top: 0px;
  background-color: @panelHeadingSearchBackgroundColor;
  margin-right: 10px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  z-index: 100;
}

.panel-default > .panel-heading > .search > .btn-group > .btn {
  padding: 0px 0px 0px 0px;
  height: 60px;
  line-height: 60px;
  width: 79px;
  text-align: center;
  font-size: 1.5em;
  border: none;
  background: @panelHeadingSearchBtnBackground;
  color: @panelHeadingSearchBtnColor;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  box-shadow: -1px 0 0 0 rgba(255, 255, 255, 0.3);
}

.panel-default > .panel-heading > .search > .btn-group > .btn:hover {
  color: @panelHeadingSearchColorHover;
}

.panel-default > .panel-heading > .search > .col-md-9,
.panel-default > .panel-heading > .search > .col-md-10 {
  padding: 0px 0px 0px 0px;
}

.search .form-control,
.search .input-group-addon,
.search .input-group > .input-group-addon {
  height: 60px;
  line-height: 60px;
  padding: 0px 0px 0px 0px;
  background: none;
  border: none;
  color: @searchColor;
  box-shadow: none;
  overflow: auto;
  background: none;
}

.search .form-control::-webkit-input-placeholder {
  color: @searchPlaceholderColor;
}

.search .form-control:-moz-placeholder {
  color: @searchPlaceholderColor;
}

.search .form-control::-moz-placeholder {
  color: @searchPlaceholderColor;
}

.search .form-control:-ms-input-placeholder {
  color: @searchPlaceholderColor;
}

.search .input-group > .input-group-addon {
  padding: 0px 25px 0px 25px;
  font-size: 1.5em;
}

.panel-default > .panel-heading > .search > .btn {
  float: left;
  width: 60px;
  height: 60px;
  line-height: 60px;
  padding: 0px 25px 0px 25px;
  border-radius: 0px;
  background: none;
  border: none;
  float: left;
}

.panel-default > .panel-heading > .search > .col-md-9 > .form-group,
.panel-default > .panel-heading > .search > .col-md-10 > .form-group {
  margin: 0px;
}

.panel-default > .panel-heading > .search > .col-md-9 > .form-group > .input-group > .input-group-addon > .icon-search,
.panel-default > .panel-heading > .search > .col-md-9 > .form-group > .input-group > .input-group-addon > .glyphicon-search,
.panel-default > .panel-heading > .search > .col-md-10 > .form-group > .input-group > .input-group-addon > .icon-search,
.panel-default > .panel-heading > .search > .col-md-10 > .form-group > .input-group > .input-group-addon > .glyphicon-search {
  background: none;
  border: none;
  vertical-align: middle;
  font-size: 20px;
}

.panel-default > .panel-heading > .btn-group > .btn,
.panel-default > .panel-heading > .btn-group > .block > .btn {
  padding: 0px 25px 0px 25px;
  border-left: none;
  border-top: none;
  border-bottom: none;
  background: none;
  font-size: 1.1em;
  font-weight: 300;
  color: @panelHeadingBtnColor;
  height: 60px;
  line-height: 60px;
  border-right: none;
  box-shadow: -1px 0 0 0 rgba(255, 255, 255, 0.3);
}

.panel-default > .panel-heading > .btn-group > .btn:first-child,
.panel-default > .panel-heading > .btn-group > .block > .btn:first-child {
  box-shadow: none;
}

.panel-default > .panel-heading > .btn-group > .btn > .icon,
.panel-default > .panel-heading > .btn-group > .block > .btn > .icon,
.panel-default > .panel-heading > .form-horizontal > .btn-group > .btn > .icon {
  font-size: 20px;
  vertical-align: middle;
}

.panel-default > .panel-heading > .btn-group > .btn:last-child,
.panel-default > .panel-heading > .btn-group > .block > .btn:last-child {
  padding: 0px 0px 0px 0px;
  width: 79px;
  text-align: center;
}

.panel-default > .panel-heading > .btn-group > .btn:first-child,
.panel-default > .panel-heading > .btn-group > .block > .btn:first-child {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

.panel-default > .panel-heading > .btn-group > .btn:last-child,
.panel-default > .panel-heading > .btn-group > .button > .btn:last-child {
  border: none;
}

.panel-default > .panel-body {
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 20px 20px 20px 20px;
}

.panel-default > .panel-table {
  padding: 0px 0px 0px 0px;
}

.panel-clean {
  box-shadow: none;
}

.panel-clean .panel-heading {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.panel-sidebar {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.panel-sidebar > .panel-heading,
.panel-white > .panel-heading {
  height: 59px;
  background-color: @panelWhiteTitleColor;
  border-bottom: @panelWhiteTitleBorderBottom;
}

.panel-sidebar > .panel-heading > .panel-title,
.panel-white > .panel-heading > .panel-title {
  height: 59px;
  line-height: 59px;
  font-size: 1.2em;
  color: @panelWhiteTitleColor;
}

.panel-sidebar > .panel-heading > .btn-group > .block,
.panel-white > .panel-heading > .btn-group > .block,
.panel-default > .panel-heading > .btn-group > .block {
  float: left;
}

.panel-sidebar > .panel-heading > .btn-group > .btn > .icon,
.panel-white > .panel-heading > .btn-group > .btn > .icon,
.panel-sidebar > .panel-heading > .btn-group > .block > .btn > .icon {
  color: @panelWhiteTitleIconColor;
  font-size: 16px;
}

.panel-sidebar > .panel-heading > .btn-group > .btn:last-child,
.panel-white > .panel-heading > .btn-group > .btn:last-child,
.panel-sidebar > .panel-heading > .btn-group > .block > .btn:last-child {
  width: auto;
  padding: 0px 20px 0px 20px;
}

.panel-sidebar > .panel-body > label,
.panel-default > .panel-body > label {
  opacity: 0.6;
  font-size: 1.0em;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 6px;
  letter-spacing: 0.2px;
  font-weight: 400;
}

.panel-sidebar > .panel-body > p,
.panel-default > .panel-body > p {
  font-size: 1.1em;
  opacity: 1.0;
  line-height: 21px;
  letter-spacing: 0.3px;
  margin-bottom: 30px;
  font-weight: 300;
}

.panel-sidebar > .panel-body > p > a {
  color: @panelSidebarLinkColor;
}

.panel-sidebar > .panel-body > p:last-child {
  margin-bottom: 0px;
}

.panel > .panel-no-padding {
  padding: 0px 0px 0px 0px;
}

.panel-body > .task-list,
.panel-body > .task-list {
  list-style: none;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.panel-body > .task-list > li {
  padding: 20px 20px 20px 20px;
  border-bottom: @panelTaskListBorderBottom;
}

.panel-body > .task-list > li:hover {
  background-color: @panelTaskListHover;
}

.panel-body > .task-list > li:last-child {
  border-bottom: none;
}

.panel-body > .task-list > li > a {
  color: @panelTaskListLink;
}

.panel-body > .task-list > li > a > h3 {
  font-size: 14px;
  line-height: 16px;
  margin: 0px 0px 4px 0px;
}

.panel-body > .task-list > li > a > h3 > span {
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  opacity: 0.7;
}

.panel-body > .task-list > li > a > p {
  opacity: 0.6;
  font-size: 12px;
  margin-bottom: 0px;
}

.panel-sidebar > .panel-body > .image-block {
  height: 200px;
  margin: -20px -20px 20px -20px;
  background-position: center center;
  background-size: cover;
}

.col-md-6 > .panel > .panel-body > .image-block {
  height: 200px;
  margin: -20px -20px 20px -20px;
  background-position: center center;
  background-size: cover;
}

.panel-sidebar > .panel-body > .image-block > .white-layer,
.col-md-6 > .panel > .panel-body > .image-block > .white-layer {
  display: block;
  width: 100%;
  height: 100%;
  background: @panelWhiteLayer;
}

.panel-sidebar > .panel-body > .image-block > .white-layer > .gradient,
.col-md-6 > .panel > .panel-body > .image-block > .white-layer > .gradient {
  width: 100%;
  height: 100%;
  display: block;
  background-image: @panelWhiteLayerGradient;
  text-align: center;
  padding-top: 30px;
}

.panel-sidebar > .panel-body > .image-block > .white-layer > .gradient > .image,
.col-md-6 > .panel > .panel-body > .image-block > .white-layer > .gradient > .image {
  display: block;
  width: 100px;
  height: 100px;
  background-position: center center;
  background-size: cover;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  margin: 0px auto;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
}

.col-md-6 > .panel > .panel-body > .image-block > .white-layer > .gradient > .image {
  width: 150px;
  height: 150px;
  -webkit-border-radius: 75px;
  -moz-border-radius: 75px;
  border-radius: 75px;
}

.panel-sidebar > .panel-body > .image-block > .white-layer > .gradient > h3 {
  color: @panelTitelColor;
  line-height: 24px;
  font-size: 20px;
  margin-top: 15px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.panel-body > .form-horizontal > .panel-block {
  float: left;
  width: 50%;
  margin: 0px 0px 0px 0px;
  padding: 20px 20px 20px 20px;
  border-right: @panelBlockBorderRight;
}

.panel-body > .form-horizontal > .panel-block:last-child {
  border: none;
}

.panel-default .task-count {
  float: left;
  display: block;
  height: 30px;
  min-width: 30px;
  line-height: 30px;
  text-align: center;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  background-color: @taskCountBackgroundColor;
  color: @taskCountColor;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  margin: -8px 0px 0px -8px;
  padding: 0px 3px 0px 3px;
}

.panel-document {
  box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none;
  margin: 10px 0px 10px 0px;
}

.panel-document .panel-heading {
  background-color: @panelDocumentHeadingBackgroundColor;
  text-align: center;
  padding: 75px 0px 40px 0px;
}

.panel-document .panel-heading .img-responsive {
  margin: 0px auto;
  max-height: 123px;
}

.panel-document .panel-body {
  text-align: center;
  padding: 30px 30px 30px 30px;
  font-weight: 400;

}

.panel-document .panel-body a {
  display: block;
  font-size: 1.2em;
  opacity: 0.4;
  line-height: 24px;
  max-height: 48px;
  color: @panelDocumentHeadingLinkColor;
}

.panel-email-preview {
  background-color: @panelEmailPreviewBackgroundColor;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.body-login .panel-default {
  box-shadow: none;
}

.body-login .panel-default .panel-heading {
  background: none;
  text-align: center;
  color: @loginScreenTitleColor;
  font-size: 2.1em;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 60px;
}

.body-login .panel {
  background: none;
}

.body-login .panel-default .panel-body {
  padding: 0px 0px 0px 0px;
}

.body-login .panel-default .panel-body .form-horizontal .input-no-margin {
  margin-bottom: 0px;
}

.body-login .panel-default .panel-body .form-horizontal .input-margin-20 {
  margin-bottom: 20px;
}

.body-login .panel-default .panel-body .form-horizontal .input-margin-30 {
  margin-bottom: 30px;
}

.body-login .panel-default .panel-body .form-horizontal .form-group .col-md-12 .input-no-border-bottom {
  -webkit-border-bottom-right-radius: 0px;
  -webkit-border-bottom-left-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  -moz-border-radius-bottomleft: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom: none;
}

.body-login .panel-default .panel-body .form-horizontal .form-group .col-md-12 .input-no-border-top {
  -webkit-border-top-left-radius: 0px;
  -webkit-border-top-right-radius: 0px;
  -moz-border-radius-topleft: 0px;
  -moz-border-radius-topright: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.body-login .panel-default .panel-body .form-horizontal .form-group .btn-primary {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0px 0px 0px 0px;
  font-size: 1.2em;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: @btnBackgroundColor;
}

.body-login .panel-default .panel-body .form-horizontal .form-group .col-md-6 {
  padding: 0px 10px 0px 10px;
}

.body-login .panel-default .panel-body .form-horizontal .form-group .col-md-6 label,
.body-login .panel-default .panel-body .form-horizontal .form-group .col-md-6 .checkbox {
  color: @loginCheckboxLabelLinkColor;
  opacity: 1.0;
  line-height: 20px;
  padding: 0px 10px 0px 10px;
}

.body-login .panel-default .panel-body .form-horizontal .form-group .col-md-6 a {
  color: @loginCheckboxLabelLinkColor;
  line-height: 20px;
  display: block;
}

.add-body-block {
  opacity: 0.6;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: @addBodyBlockColor;
  line-height: 59px;
  margin-right: 20px;
}

@media screen and (min-width: 1200px) {
  .body-login .panel-default .panel-body {
    padding: 0px 40px 0px 40px;
  }
}

.panel-block > #mail_blocks > .col-md-12 {
  padding: 0px 20px;
}

.panel-block > #mail_blocks > .col-md-12 > .form-group {
  margin: 0;
}

.panel-default > .panel-filter {
  background-color: @panelFilterColor;
  -webkit-border-bottom-left-radius: 0px;
  -webkit-border-bottom-right-radius: 0px;
  -moz-border-radius-bottomleft: 0px;
  -moz-border-radius-bottomright: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.panel-default > .panel-filter > .panel-title {
  padding: 0px 0px 15px 5px;
}