.pagination {
  margin: 0px;
  border: 1px solid #eceeef;
}
.pagination > li > a,
.pagination > li > span {
  height: 36px;
  width: 36px;
  line-height: 36px;
  padding: 0px 0px 0px 0px;
  text-align: center;
  color: #1a75bc;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #29aae2;
  border-color: #29aae2;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #1a75bc;
  background-color: #f4fbfd;
}
.pagination > li > a > .icon {
  font-size: 10px;
}
