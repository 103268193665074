.navbar {
  height: 80px;
  background-color: #ffffff;
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.03);
  border: none;
}
.navbar-nav > li > a {
  line-height: 80px;
  padding: 0px 25px 0px 25px;
  opacity: 0.5;
  font-size: 1.2em;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.navbar-default .navbar-nav > li > a {
  color: #000000;
}
.navbar-default .navbar-nav > li.active > a,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #000000;
  opacity: 1.0;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #000000;
  background: none;
}
.navbar-brand {
  height: 80px;
  line-height: 80px;
  padding: 0px 68px 0px 68px;
  background-color: #104873;
  font-weight: 500;
  letter-spacing: 0.3px;
  font-size: 1.4em;
}
.navbar-default .navbar-brand {
  color: #000000;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #000000;
  background-color: #104873;
}
.navbar-right .navbar-profile {
  display: block;
  margin-top: 17px;
  margin-right: 10px;
  height: 46px;
  width: 46px;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  border-radius: 23px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-size: cover;
}
.navbar-right .glyphicon {
  vertical-align: middle;
}
.navbar-right .glyphicon-cog {
  font-size: 20px;
}
.navbar-right .glyphicon-chevron-down {
  font-size: 6px;
  line-height: 80px;
}
.navbar-right > li {
  margin: 0px 0px 0px 0px;
}
.navbar-right > li:last-child {
  margin: 0px 30px 0px 10px;
}
.navbar-right > li > a {
  opacity: 1.0;
  margin: 0px 0px 0px 10px;
  padding: 0px 0px 0px 0px;
}
.navbar-right .dropdown-menu-right {
  width: 220px;
}
.navbar-right .mask {
  display: block;
  background: #000000;
  width: 38px;
  height: 38px;
  line-height: 35px;
  -webkit-border-radius: 19px;
  -moz-border-radius: 19px;
  border-radius: 19px;
  margin: 21px 0px 21px 0px;
  text-align: center;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}
.navbar-right .mask .glyphicon-stats,
.navbar-right .mask .glyphicon-cog {
  color: #29aae2;
  vertical-align: middle;
  font-size: 22px;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  color: #000000;
  background: none;
}
.navbar .task-count {
  float: right;
  display: block;
  height: 30px;
  min-width: 30px;
  line-height: 30px;
  text-align: center;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  background-color: #eb2f5e;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  margin: -8px -8px 0px 0px;
  padding: 0px 3px 0px 3px;
}
.navbar .dropdown-menu .task-count {
  margin: 14px 0px 0px 0px;
  color: #000000;
  box-shadow: none;
}
.navbar-default .icon-largeArrowLeft {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 20px;
}
