@import "variables";

.icon-green {
  color: @iconGreen;
}

.table > tbody > tr > td > .icon {
  font-size: 25px;
  line-height: 45px;
  margin-right: 10px;
}

.icon-red {
  color: @iconRed;
}

.icon-delete {
  font-size: 25px;
  line-height: 45px;
  vertical-align: middle;
}