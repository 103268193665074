@import "variables";

.mail h1 {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.2px;
  color: @mailH1Color;
}

.mail p {
  font-weight: 300;
  line-height: 1.41;
  letter-spacing: 0.2px;
  color: @mailPColor;
}