.dropdown-menu {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none;
}

.dropdown-menu > li > a {
  height: 59px;
  line-height: 59px;
}

.dropdown-menu > li {
  border-bottom: 1px solid #e5e5e5;
}

.dropdown-menu > li:last-child {
  border: none;
}
