@import "variables";

.login {
  background-color: #ffffff;
  padding: 0;

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .left-panel {
    background-image: url('/images/login.jpg');
    background-size: cover;
    height: 100vh;
    padding-left: 0;
    padding-right: 0;

    .overlay {
      display: block;
      width: 100%;
      height: 100vh;
      background-image: linear-gradient(313deg, rgba(217, 34, 42, 0.0), rgba(217, 34, 42, 0.9));
      padding-left: 10px;
      padding-right: 10px;

      .logo {
        margin-top: 40px;
        margin-left: 40px;
        height: 50px;
      }

      h1 {
        font-size: 50px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: 0.5px;
        color: red;
        padding-top: 150px;
        text-align: left;
      }
    }

    @media (max-width: 1199px) {
      .overlay {
        h1 {
          padding-top: 100px;
          padding-left: 0px;
        }
      }
    }
  }

  .right-panel {
    height: 100vh;
    padding-left: 0;
    padding-right: 0;
    box-shadow: -40px 0 60px -20px rgba(0, 0, 0, 0.25), -40px 0 60px -20px rgba(222, 14, 32, 0.4);

    h2 {
      color: #242424;
      font-size: 42px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.4px;
      opacity: 0.8;
      padding-top: 200px;
      text-align: left;
      margin-bottom: 40px;
    }

    p {
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.42;
      letter-spacing: 0.2px;
      color: #8e8e8e;
      margin-bottom: 20px;
    }

    a {
      color: #242424;
      font-style: italic;
      transition: opacity .1s ease-out 0s;

      &:hover {
        color: #242424;
        opacity: 1;
        text-decoration: none;
      }
    }

    .has-error .form-control {
      border: solid 2px #d0011b;
    }

    .forgot-password {
      color: #242424;
      font-style: normal;
      margin-left: 20px;
      line-height: 52px;
      margin-top: 30px;
      opacity: 0.6;
    }

    .form-control {
      -webkit-appearance: none;
      margin: 5px 5px;
      height: 61px;
      line-height: 26px;
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.3px;
      color: #8e8e8e;
      border-color: #a9a9a9;
      &:focus {
        border: solid 2px rgba(116, 116, 116, 0.65);
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
      }
    }

    .help-block {
      padding-left: 20px;
      font-weight: normal;
      font-size: 20px;
    }

    .btn-login {
      margin-left: 5px;
      margin-top: 30px;
      padding: 16px 50px;
      border: none;
      background-color: #2e2e2e;
      border-radius: 6px;
      font-weight: bold;
      letter-spacing: 0.2px;
      color: #ffffff;
      transition: background-color .2s ease-out 0s;

      &:hover {
      background-color: #000000;
      }

      &:focus {
        background-color: #000000;
        outline: none;
      }
    }

    a {
      margin-top: 20px;
    }

    @media (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
      text-align: center;
      .row {
        margin-left: 0;
        margin-right: 0;
      }

      h2 {
        padding-top: 50px;
      }

      .form-group {
        margin-right: 0;
      }

      .btn-jobbo-1 {
        width: 100%;
      }
    }

    @media (max-width: 1199px) {
      h2 {
        padding-top: 100px;
      }
    }
  }
}